<mat-card [ngClass]="
    item?.percentage === 100 ||
    item?.completed === true ||
    item?.precentage === 100
      ? 'complete'
      : ''
  " *ngIf="progressType === 'In Progress' && !item.completed || progressType === 'Completed' && item.completed">
  <mat-card-title-group flexLayout="row" fxLayoutAlign="start start" fxFlex="100">
    <mat-card-subtitle (click)="selectItem(item)" [ngClass]="item.type ? 'card-title-' + item.type : ''"
      flexLayout="row" style="font-weight: bold; font-family: Roboto; font-size: 9px">
      {{ item.type == 'package' ? 'MODULE' : (item.type | uppercase) }}
      <span *ngIf="archivedResource" class="itemArchived">
        (Item Archived)</span>
      <span style="
          margin-left: 1em;
          font-size: 9px;
          font-style: italic;
          font-variant: normal;
          font-family: Roboto;
          color: #d0d0d0;
        ">
        {{ item.addedAt ? 'Added ' : 'Last updated ' }}
        {{
        item.addedAt || item.createdAt || item.resource?.updatedAt | from
        }}</span>
    </mat-card-subtitle>
    <button *ngIf="item?.resource?.id" class="favorite" (click)="addFavorite()" mat-icon-button>
      <mat-icon matTooltip="{{ item.isFavorite ? 'Remove Favorite' : 'Add Favorite' }}" matTooltipPosition="above">
        <ng-icon class="not-favorite" name="{{ getTypeIcon('favorite') }}" size="25px" [hidden]="item.isFavorite">
        </ng-icon>
        <ng-icon class="is-favorite" name="{{ getTypeIcon('favorite', true) }}" size="25px" [hidden]="!item.isFavorite">
        </ng-icon>
      </mat-icon>
    </button>
    <mat-card-title (click)="selectItem(item)" class="itemTitle" flexLayoutAlign="row">{{ item.name }}</mat-card-title>
    <mat-card-title (click)="selectItem(item)" flexLayoutAlign="row" fxLayoutAlign="space-between center">
      <div class="description" >{{item.description || item.resource?.description}}</div>
    </mat-card-title>
    <mat-card-title (click)="selectItem(item)" flexLayout="row" fxLayoutAlign="space-between center"
      class="durationInfo">
      <div class="icon">
        <ng-container [ngSwitch]="item.type">
          <span *ngSwitchCase="'quiz'">
            <ng-icon size="50px" name="{{ getTypeIcon('quiz', true) }}"></ng-icon>
          </span>
          <span *ngSwitchCase="'course'">
            <ng-icon size="50px" name="{{ getTypeIcon('course', true) }}"></ng-icon>
          </span>
          <span *ngSwitchCase="'roadmap'">
            <ng-icon size="50px" name="{{ getTypeIcon('roadmap', true) }}"></ng-icon>
          </span>
          <span *ngSwitchCase="'document'">
            <ng-icon size="50px" name="{{ getTypeIcon('document', true) }}" color="#000000"></ng-icon>
          </span>
          <span *ngSwitchCase="'library'">
            <ng-icon size="50px" name="{{ getTypeIcon('library', true) }}" color="#000000"></ng-icon>
          </span>
          <span *ngSwitchCase="'video'">
            <ng-icon size="50px" name="{{ getTypeIcon('video', true) }}" color="#000000"></ng-icon>
          </span>
          <span *ngSwitchCase="'audio'">
            <ng-icon size="50px" name="{{ getTypeIcon('audio', true) }}" color="#000000"></ng-icon>
          </span>
          <span *ngSwitchCase="'external link'">
            <ng-icon size="50px" name="{{ getTypeIcon('external link', true) }}" color="#000000"></ng-icon>
          </span>
          <span *ngSwitchCase="'package'">
            <ng-icon size="50px" name="{{ getTypeIcon('package', true) }}"></ng-icon>
          </span>
        </ng-container>
      </div>
      <div [ngSwitch]="item.type" [hidden]="item?.percentage === 100 || item?.completed">

        <span *ngSwitchCase="'course'" class=""></span>
        <span *ngSwitchCase="'roadmap'" [ngClass]="
            item.resource && objectKeys(item.resource.tasks).length > 0
              ? 'duration'
              : 'notStarted'
          ">{{
          item.resource && objectKeys(item.resource.tasks).length > 0
          ? objectKeys(item.resource.tasks).length + ' tasks'
          : 'Not Yet Started'
          }}</span>
        <span *ngSwitchCase="'document'" [ngClass]="
            item.resource && item.resource.sources?.original.meta.page_count
              ? 'duration'
              : 'notStarted'
          ">
          <span>{{
            item.resource && item.resource.sources?.original.meta.page_count
            ? item.resource.sources?.original.meta.page_count + ' pages'
            : 'Not Yet Started'
            }}</span>
        </span>
        <span *ngSwitchCase="'video'" [ngClass]="
            item.resource && item.resource.sources.ipad_high.meta.duration
              ? 'duration'
              : 'notStarted'
          ">{{
          item.resource && item.resource.sources.ipad_high.meta.duration
          | timeFormat
          }}</span>
        <span *ngSwitchCase="'audio'" [ngClass]="
            item.resource && item.resource.sources.mp3_standard.meta.duration
              ? 'duration'
              : 'notStarted'
          ">{{
          item.resource && item.resource.sources.mp3_standard.meta.duration
          ? (item.resource.sources.mp3_standard.meta.duration | timeFormat)
          : 'Not Yet Started'
          }}</span>
        <span *ngSwitchCase="'package'" [ngClass]="item.moduleTotalSlides ? 'duration' : 'notStarted'">{{
          item.moduleTotalSlides
          ? item.moduleTotalSlides + ' slides'
          : 'Not Yet Started'
          }}</span>
        <span *ngSwitchCase="'library'" [ngClass]="
            item.resource && item.resource.items && objectKeys(item.resource.items).length > 0
              ? 'duration'
              : 'notStarted'
          ">{{
          item.resource && item.resource.items && objectKeys(item.resource.items).length > 0
          ? objectKeys(item.resource.items).length + ' items'
          : 'Not Yet Started'
          }}</span>
        <span *ngSwitchCase="'Quiz'" [ngClass]="
            item.resource && item.resource.questionCount
              ? 'duration'
              : 'notStarted'
          ">
          {{
          item.resource && item.resource.questionCount
          ? item.resource.questionCount + ' qns'
          : 'Not Yet Started'
          }}</span>
      </div>
      <div *ngIf="
          item.type != 'Quiz' &&
          item.type != 'roadmap' &&
          item.type != 'library' &&
          section != 'library' &&
          !item.isSimple
        " flexLayout="column" [ngClass]="
          item?.percentage === 100 ||
          item?.precentage === 100 ||
          item?.completed
            ? 'itemProgress complete'
            : 'itemProgress'
        ">
        <div [hidden]="
            item?.percentage === 100 ||
            item?.precentage === 100 ||
            item?.completed
          " class="percentage">
          {{ item?.percentage || item?.precentage }}% Complete
        </div>
        <div [style.left]="'10px'" *ngIf="
            item?.percentage === 100 ||
            item?.precentage === 100 ||
            item?.completed
          " class="percentage">
          <ng-icon size="25px" color="#185795" name="hero-check-circle-solid"></ng-icon>
          <span [style.color]="'#185795'"> COMPLETE</span>
        </div>
        <mat-progress-bar *ngIf="item?.completed" [value]="100">
        </mat-progress-bar>
        <mat-progress-bar [hidden]="item?.completed" [value]="item?.percentage || item?.precentage">
        </mat-progress-bar>
      </div>
    </mat-card-title>
  </mat-card-title-group>
</mat-card>