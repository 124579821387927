<div class="course-builder">
  <div class="course-builder-wrapper" layout="column">
    <div class="course-builder-content">
      <h1>{{ course.name }}</h1>
      <div class="course-builder-content-item" *ngIf="course.content">
        <h3
          class="course-builder-heading"
        >{{course.content?.heading}}</h3>
        <div
          class="course-builder-body"
        >{{course.content?.body}}</div>
      </div>
    </div>
    <div *ngIf="tasks && tasks.length > 0">
      <app-task
        *ngFor="let t of tasks"
        [class]="'course-builder-item'"
        [task]="t"
      ></app-task>
    </div>
  </div>
</div>
