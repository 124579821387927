import { Clipboard } from '@angular/cdk/clipboard'
import { Location } from '@angular/common'
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Clipboard as NativeClipBoard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'
import { MdbModalRef } from 'mdb-angular-ui-kit/modal'
import 'moment-timezone'
import { Resource } from '../interfaces/resource'
import { DelphireApiService } from '../services/delphire-api.service'
import { DynamicLinkService } from '../services/dynamic-link.service'
import { ProgressService } from '../services/tracking/progress.service'
import { TrackService } from '../services/tracking/track.service'
import { TrackingService } from '../services/tracking/tracking.service'

@Component({
  selector: 'app-trackable-multimedia-modal',
  templateUrl: './trackable-multimedia-modal.component.html',
  styleUrls: ['./trackable-multimedia-modal.component.sass'],
  providers: [{ provide: TrackingService, useClass: ProgressService }]
})
export class TrackableMultiMediaModalComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('mediaPlayer') set content(content: ElementRef<HTMLMediaElement>) {
    if (content) {
      this.mediaPlayer = content
      this.mediaPlayer.nativeElement.addEventListener(
        'play',
        this.startEvent.bind(this)
      )
      this.mediaPlayer.nativeElement.addEventListener(
        'pause',
        this.pauseEvent.bind(this)
      )
      this.mediaPlayer.nativeElement.addEventListener(
        'seeked',
        this.setSeeked.bind(this)
      )
      this.mediaPlayer.nativeElement.addEventListener(
        'ended',
        this.endedEvent.bind(this)
      )
    }
  }

  public static START = 'MEDIA_STARTED'
  public static CURRENT = 'MEDIA_CURRENT_TIME'
  public static COMPLETE = 'MEDIA_COMPLETE'

  close(): void {
    this.modalRef.close({ animate: false })
    const event: Event = new Event('pause')
    this.mediaPlayer.nativeElement.dispatchEvent(event)
    this.location.back()
  }
  setSeeked(): void {
    this.seeked = true
  }
  startEvent(): void {
    console.log("START EVENT ::: ::: TRACKABLE MEDIA")
    const media: ElementRef<HTMLMediaElement> = this.mediaPlayer
    const { mediaLastTime } = this.resource

    if (
      mediaLastTime &&
      mediaLastTime > media.nativeElement.currentTime &&
      !this.seeked
    ) {
      media.nativeElement.currentTime = mediaLastTime
    }

    const params = {
      namespace: 'MediaProgress',
      id: this.resource.id,
      type: TrackableMultiMediaModalComponent.START,
      originalKey: this.resource.id,
      name: this.resource.name,
      payload: {
        percentage: this.resource.progress || 0,
        currentTime: mediaLastTime || this.actualTime,
        duration: media.nativeElement.duration,
        completed: this.resource.completed
      }
    }
    this.trackingService.addEvent(params)
  }

  pauseEvent(): void {
    console.log("PAUSE EVENT ::: ::: TRACKABLE MEDIA")
    const media: ElementRef<HTMLMediaElement> = this.mediaPlayer
    const { progress, completed, mediaPercentage } = this.resource
    const percentage: number = Math.floor(
      (media.nativeElement.currentTime / media.nativeElement.duration) * 100
    )

    let newPrecentage: number = 0
    let complete: boolean = false
    const prevProgress: number = progress || mediaPercentage || 0
    if (percentage > prevProgress) {
      newPrecentage = percentage
    } else {
      newPrecentage = prevProgress
    }

    if (percentage > 90 || completed) {
      newPrecentage = 100
      complete = true
    }

    const params = {
      namespace: 'MediaProgress',
      id: this.resource.id,
      name: this.resource.name,
      type: TrackableMultiMediaModalComponent.CURRENT,
      originalKey: this.resource.id,
      payload: {
        percentage: newPrecentage,
        currentTime: media.nativeElement.currentTime,
        duration: media.nativeElement.duration,
        completed: complete
      }
    }
    this.trackingService.addEvent(params)
  }

  endedEvent(): void {
    console.log("ENDED EVENT ::: ::: TRACKABLE MEDIA")
    const media: ElementRef<HTMLMediaElement> = this.mediaPlayer
    const params = {
      namespace: 'MediaProgress',
      name: this.resource.name,
      id: this.resource.id,
      type: TrackableMultiMediaModalComponent.CURRENT,
      originalKey: this.resource.id,
      payload: {
        percentage: 100,
        currentTime: media.nativeElement.currentTime,
        duration: media.nativeElement.duration,
        completed: true
      }
    }
    this.trackingService.addEvent(params)
  }
  async generateLink() {
    const link = await this.dynamicLinkService.generateLink()
    if (Capacitor.isNativePlatform()) {
      await NativeClipBoard.write({ string: link })
    } else {
      this.clipboard.copy(link)
    }
    this.snackbar.open('Link Copied to ClipBoard!', 'Ok', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'linkSnackBar'
    })
  }

  resource!: Resource

  videoUrl: string | undefined

  mediaPlayer!: ElementRef<HTMLMediaElement>

  audioUrl: string | undefined

  isCollapsed: boolean = false

  lastPercent: number = 0
  lastEventPercent: number = 0
  seeked: boolean = false
  actualTime: number = 0
  constructor(
    public modalRef: MdbModalRef<TrackableMultiMediaModalComponent>,
    private delphireAPI: DelphireApiService,
    private track: TrackService,
    private trackingService: TrackingService,
    private location: Location,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard,
    private dynamicLinkService: DynamicLinkService
  ) {}

  ngOnInit(): void {
    this.delphireAPI
      .postRequest('resources/getDownloadUrl', {
        id: this.resource.id,
        sourceKey: this.resource.type === 'video' ? 'ipad_high' : 'mp3_standard'
      })
      .then((response) => {
        response.subscribe((data) => {
          if (this.resource.type === 'video') {
            this.track.setResourceType('video')
            this.videoUrl = data.body.result.url
          } else {
            this.track.setResourceType('audio')
            this.audioUrl = data.body.result.url
          }
        })
      })
  }

  ngAfterViewInit(): void {}
}
