import { Pipe, PipeTransform } from '@angular/core'
import _ from 'lodash'

@Pipe({
  name: 'libraryName',
  pure: false
})
export class LibraryNamePipe implements PipeTransform {
  transform(libraries: any, args: string): string {
    const library = _.find(libraries, { id: args })
    if (library) {
      // Substring to hide (boco test) for demo remove
      return library.name
    } else {
      return ''
    }
  }
}
