<div class="gpt-sidebar">

  <div class="gpt-container" fxFlex fxLayout="row" [ngClass]="{ 'open': gptOpen }">

    <!-- <div class="sessions" fxFlex="25">
      side
    </div>

    <div class="document-chat" fxFlex="75" fxLayout="column"> -->
    <div class="document-chat" fxFlex fxLayout="column">
      <div class="close-sidebar" [ngClass]="{ 'open': gptOpen }" (click)="close()" fxLayoutAlign="center center">
        <ng-icon name="hero-x" size="20px"></ng-icon>
      </div>

      <div class="top" fxLayout="column">
        <div class="header" fxLayout="row">
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
            <div class="title">Ask Modal</div>
          </div>
          <!-- <div class="about" fxFlex fxLayoutAlign="end center">
            <a href="javascript:void(0)">What is ModalGPT?</a>
            <ng-icon name="HeroInformationCircle" size="1.5em"></ng-icon>
          </div> -->
        </div>
        <!-- <div class="document">
          <div class="title">{{ gptDocumentTitle }}</div>
        </div> -->
        <div class="document-select">
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selectedDocument" (selectionChange)="handleDocumentSelect($event.value)" multiple
              placeholder="Select a document(s)">
              <mat-option *ngFor="let task of tasks" [value]="task"
                [disabled]="isOptionDisabled(task)">{{task.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <hr>

      <div class="middle" fxLayout="column-reverse">

        <div *ngFor="let qaPair of gptChatSession; index as qaIdx" fxLayout="column">

          <div class="question" fxLayout="row" fxLayoutAlign="end start">
            <div class="text">{{ qaPair.message.text }}</div>
            <div class="avatar">
              <img src="/assets/chat-gpt/avatar-user.svg" />
            </div>
          </div>

          <div *ngFor="let res of qaPair.response; index as resIdx" class="response" fxLayout="row"
            fxLayoutAlign="start start">

            <div class="avatar rx">
              <img src="/assets/chat-gpt/avatar-rx.svg" />
            </div>

            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="loader" *ngIf="!res.text">
                <div class="dot-pulse"></div>
              </div>

              <div *ngIf="res.text">

                <div fxLayout="row">
                  <div class="text">{{ res.text }}</div>

                  <div fxLayoutAlign="start start" fxLayout="row" class="thumbs">

                    <ng-icon class="thumb" name="hero-thumb-up" size="20px" *ngIf="!res.feedback || res.feedback=='up'"
                      [ngClass]="{ 'disabled': res.feedback}" (click)="giveFeedback(qaIdx, 'up')"></ng-icon>

                    <ng-icon class="thumb" name="hero-thumb-down" size="20px"
                      *ngIf="!res.feedback || res.feedback=='down'" [ngClass]="{ 'disabled': res.feedback}"
                      (click)="giveFeedback(qaIdx, 'down')"></ng-icon>
                  </div>
                </div>

                <div *ngIf="res.task" class="knowledge-link">
                  <a (click)="handleLink(res.task)">{{ res.task.name }} -
                    Click to
                    see
                    the
                    knowledge behind this
                    answer</a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <hr>

      <div class="bottom" fxLayout="row">
        <input [disabled]="!gptSelectedTasks.length" type="text" [(ngModel)]="gptQuestionAsked"
          (keyup)="onAskKeyUp($event)" placeholder="Ask a question" />
        <div class="ask-button" (click)="askGPT()" [ngClass]="{ 'disabled': !gptQuestionAsked}" fxLayout="row"
          fxLayoutAlign="center center">ASK →</div>
      </div>

      <div class="rxds">
        Powered by RX Data Science
      </div>
    </div>


  </div>

  <div class="cover" [ngClass]="{ 'open': gptOpen }" (click)="close()"></div>
