<div class="quiz-layout">

  <div fxLayout="row" fxLayout.xs="column">

    <div class="left" fxFlex="50" fxFlex.xs="100">
      <div title="close quiz" class="close-button" aria-label="close" (click)="quit()" fxHide.gt-xs
        fxLayoutAlign="center center">
        <span class="material-icons">close</span>
      </div>

      <div class="quiz-landing" fxLayout="column" fxLayoutAlign="top center">
        <i class="icon-quiz"></i>
        <h2>{{quiz.name}}</h2>
        <div class="quiz-description" [innerHtml]="quiz.description">
        </div>
      </div>

    </div>

    <div class="right" fxFlex>

      <div title="close quiz" class="close-button" aria-label="close" (click)="quit()" fxHide.xs
        fxLayoutAlign="center center">
        <span class="material-icons">close</span>
      </div>

      <div class="quiz-landing" fxLayout="column" fxLayoutAlign="center center">
        <p>
          There are {{ quiz.questionCount }} questions in this quiz. If you choose to leave before all
          {{ quiz.questionCount }} questions are answered you will not get credit for completing this quiz.
        </p>
        <button mat-button class="start-button" (click)="start()">
          START
        </button>
      </div>

    </div>

  </div>
</div>