<div class="quiz-layout quiz-complete" fxLayout="column" fxFlex>
  <div title="close quiz" class="close-button" aria-label="close" (click)="quit()" fxLayoutAlign="center center">
    <span class="material-icons">close</span>
  </div>
  <div class="quiz-complete-layout" fxLayout="column">
    <div class="quiz-name">{{quiz.name}}
    </div>
    <div class="quiz-message" fxLayout="column" fxLayoutAlign="center center">
      <h2>You answered {{ answeredCorrect }}/{{ quiz.questionCount }} questions correctly for a score of {{ quizScore }}%.</h2>
      <h2 *ngIf="quizPassed">{{quiz.passMessage}}</h2>
      <h2 *ngIf="!quizPassed">{{quiz.failMessage}}</h2>
      <div class="quiz-result" fxLayoutAlign="center center">
        <!-- <hr [ngClass]="{ pass: quizPassed, fail: !quizPassed }"> -->
        <mat-divider class="answer-divider" [ngClass]="{ pass: quizPassed, fail: !quizPassed }"></mat-divider>
        <div class="icon" [ngClass]="{ pass: quizPassed, fail: !quizPassed }">
          <span class="material-icons" *ngIf="quizPassed">thumb_up</span>
          <span class="material-icons" *ngIf="!quizPassed">thumb_down</span>
        </div>
      </div>
    </div>
    <div class="quiz-buttons" fxLayout="row">
      <div (click)="retake()" fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
        <div class="completed-button">Retake Quiz</div>
      </div>
      <div (click)="quit()" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div class="completed-button">Close Quiz</div>
      </div>
    </div>
  </div>
</div>
