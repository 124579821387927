import { Injectable } from '@angular/core'
import {
  collection,
  CollectionReference,
  doc,
  Firestore,
  getDoc,
  getDocs
} from '@angular/fire/firestore'
import { Group } from '@delphire/dto'
import { Either, pipe, t } from '@delphire/fp'
import { first } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { FirebaseService } from './firebase.service'

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  readonly groupCollection: CollectionReference
  databaseRootUrl: string = `https://${environment.firebaseConfig.iamId}.firebaseio.com`

  constructor(readonly firestore: Firestore, private fb: FirebaseService) {
    this.groupCollection = collection(firestore, 'groups')
  }

  async read(id: string): Promise<Group.Type | null> {
    const groupDoc = doc(this.groupCollection, id)

    const groupSnap = await getDoc(groupDoc)
    return pipe(
      groupSnap.data(),
      Group.codec.decode,
      Either.mapLeft(t.PathReporter.failure),
      Either.fold(
        (errors) => {
          console.error(`Could not decode group`, errors)
          return null
        },
        (result) => {
          return result
        }
      )
    )
  }
  async getGroupById(id: string): Promise<Group.Type | null> {
    if (typeof id !== 'string') return null

    const groupDoc = await this.read(id)

    if (groupDoc == null) return null

    return { ...groupDoc }
  }

  async getGroups (id: string): Promise<Group.Type[] | null >{
    const response = await this.fb
      .db(this.databaseRootUrl)
      .object(environment.firebasePaths.state + 'tenantGroupAssociation/tenantGroups/' +id)
      .pipe(first())
      .toPromise()
    const groups: Group.Type[] = [];
    const querySnapshot = await getDocs(this.groupCollection);
    querySnapshot.forEach((doc) => {
      return pipe(
        doc.data(),
        Group.codec.decode,
        Either.mapLeft(t.PathReporter.failure),
        Either.fold(
          (errors) => {
            console.error(`Could not decode group`, errors)
            return null
          },
          (result) => {
            if(Object.keys(response)?.find(r => r == doc.id)){
              groups.push({...result, groupId: doc.id})
            }
          }
        )
      );
    });
    return groups;
  }
}
