<ng-container *ngFor="let q of questions; let i = index">
  <ng-container *ngIf="position == i">

    <div class="quiz-layout">
      <div fxLayout="row" fxLayout.xs="column">
        <div class="left" fxFlex="50" fxFlex.xs="100" fxLayout="column">

          <div title="close quiz" class="close-button" aria-label="close" (click)="quit()" fxHide.gt-xs
            fxLayoutAlign="center center">
            <span class="material-icons">close</span>
          </div>

          <div class="quiz-info" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
            <div class="quiz-name" fxFlex="80" >{{quiz.name}}</div>
            <div class="question-count" fxFlex fxLayoutAlign="end center">{{ position + 1 }}/{{ quiz.questionCount }}
            </div>
          </div>

          <div class="quiz-question" fxLayout="column" fxLayoutPadding fxLayoutAlign="center center">

            <h4 class="question-text" [ngClass]="{ 'question-fade': answered && q.remediation}"
              >{{q.text}}</h4>

            <div fxHide.xs class="answer-result" *ngIf="answered" fxLayoutAlign="center center">
              <mat-divider class="answer-divider"></mat-divider>
              <div class="icon">
                <span class="material-icons" *ngIf="correct">thumb_up</span>
                <span class="material-icons" *ngIf="!correct">thumb_down</span>
              </div>
            </div>

            <div fxHide.xs class="remediation" *ngIf="answered && q.remediation" >{{q.remediation}}</div>

          </div>
        </div>

        <div class="right" fxFlex fxLayout.xs="column" fxLayoutAlign="center center">

          <div title="close quiz" class="close-button" aria-label="close" (click)="quit()" fxHide.xs
            fxLayoutAlign="center center">
            <span class="material-icons">close</span>
          </div>

          <div class="answers" [ngClass]="{ 'answer-selected': selected.length && !answered || selected.length &&
            answered && !q.remediation}" fxLayoutPadding fxLayout="column" fxLayoutAlign="center center">
            <div class="answer" fxFlex *ngFor="let a of q.answers; let i = index"
              [ngClass]="{ selected: isSelected(i), answercorrectbdr : answerIsCorrect(i) && answered && quiz.showCorrectAnswers, answerwrongbdr : !answerIsCorrect(i) && answered && quiz.showCorrectAnswers, dev: answerIsCorrect(i) && dev }"
              (click)="toggleAnswer(i)">
              <div class="answer-text" fxLayoutAlign="start center" *ngIf="!quiz.showCorrectAnswers">{{ a }}</div>
              <div class="answer-text" fxLayoutAlign="start center"
                [ngClass]="{ correct: answerIsCorrect(i) && answered, incorrect: !answerIsCorrect(i) && answered }"
                *ngIf="quiz.showCorrectAnswers">{{ a }}
              </div>
              <span class="material-icons indicator" *ngIf="isSelected(i) && answered &&
                answerIsCorrect(i)">check_circle_outline</span>
              <span class="material-icons indicator"
                *ngIf="isSelected(i) && answered && !answerIsCorrect(i)">highlight_off</span>
            </div>

            <div fxHide.xs>
              <div class="result incorrect" fxLayout="row" *ngIf="answered && !correct">
                <span class="material-icons">highlight_off</span>
                <div class="quiz-answer-result incorrect">Incorrect</div>
              </div>
              <div class="result correct" fxLayout="row" *ngIf="answered && correct">
                <span class="material-icons">check_circle_outline</span>
                <div class="quiz-answer-result correct">Correct</div>
              </div>
            </div>

          </div>

          <div fxHide.gt-xs class="mobile-remediation" *ngIf="answered && q.remediation" fxLayoutPadding>

            <div class="result incorrect" fxLayout="row" *ngIf="answered && !correct" fxLayoutAlign="center center">
              <span class="material-icons">highlight_off</span>
              <div class="quiz-answer-result incorrect"div>Incorrect</div>
            </div>

            <div class="result correct" fxLayout="row" *ngIf="answered && correct" fxLayoutAlign="center center">
              <span class="material-icons">check_circle_outline</span>
              <div class="quiz-answer-result correct">Correct</div>
            </div>

            <div class="answer-result" *ngIf="answered" fxLayoutAlign="center center">
              <hr>
              <div class="icon">
                <span class="material-icons" *ngIf="correct">thumb_up</span>
                <span class="material-icons" *ngIf="!correct">thumb_down</span>
              </div>
            </div>

            <div class="remediation" *ngIf="answered && q.remediation" >{{q.remediation}}
            </div>
          </div>

          <div class="submit-continue" [ngClass]="{ active: selected.length, inactive: answered }" (click)="submit()">
            <span *ngIf="!answered">Submit</span>
            <span class="continue-arrow" *ngIf="answered">Continue</span>
            <span class="material-icons arrow" *ngIf="answered">arrow_circle_right</span>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
</ng-container>










