import { Injectable } from '@angular/core'
import _ from 'lodash'

import { environment } from '../../environments/environment'
import { Course } from '../interfaces/course'
import { DelphireUser } from '../interfaces/delphire-user'
import { ExternalLink } from '../interfaces/external-link'
import { Library } from '../interfaces/library'
import { Quiz } from '../interfaces/quiz'
import { Resource } from '../interfaces/resource'
import { Roadmap } from '../interfaces/roadmap'
import { Task } from '../interfaces/task'
import { Verbalizer } from '../interfaces/verbalizer'
import { DelphireApiService } from './delphire-api.service'
import { FirebaseService } from './firebase.service'
import { LocalStorageService } from './local-storage-service.service'

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  checkExclusiveReaders(item: Task): Task {
    item.permitted = true
    if (item.type?.toLocaleLowerCase() === 'resource') {
      if (item.resource?.exclusiveReaders) {
        item.permitted = false
        const groups = item.resource?.exclusiveReaders.groups
        const users = item.resource?.exclusiveReaders.users
        const user: DelphireUser = this.localStorage.get('delphireUser')
        if (groups) {
          const groupKeys = Object.keys(groups)
          const inGroups = _.indexOf(groupKeys, user.groupId)
          if (inGroups > -1) {
            item.permitted = true
          }
        }
        if (users) {
          const userKeys = Object.keys(users)
          const inUsers = _.indexOf(userKeys, user.user?.id)
          if (inUsers > -1) {
            item.permitted = true
          }
        }
      }
    }
    return item
  }
  get(item: Task, componentName: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      const { databaseUrl } = this.localStorage.get('currentSpace')
      if (!item.link && componentName && componentName === 'RoadmapComponent') {
        console.log('%c[ SPOT 1 ]', 'color: red')
        return resolve(item)
      }

      if (item.link && componentName && componentName === 'RoadmapComponent') {
        console.log('%c[ SPOT 2 ]', 'color: red')
        item.type = item.link.type
        item.parameters = item.link.parameters || {}
      }

      item.permitted = true

      if (item.type && item.parameters)
        switch (item.type.toLowerCase()) {
          case 'none':
            resolve(item)
            break
          case 'external link':
            const linkId: string | undefined =
              item.parameters?.linkId != null
                ? item.parameters.linkId
                : item.parameters?.externalLinkId
            if (!item.parameters?.externalLinkId) {
              resolve(item)
            } else {
              this.fbS
                .db(databaseUrl)
                .object(
                  environment.firebasePaths.space + '/externalLinks/' + linkId
                )
                .subscribe(
                  (link: ExternalLink) => {
                    item.link = link
                    item.link.description = link.description
                    return resolve(item)
                  },
                  (error: any) => {
                    return reject(error)
                  }
                )
            }
            break
          case 'roadmap':
            if (!item.parameters.roadmapId) {
              resolve(item)
            } else {
              this.fbS
                .db(databaseUrl)
                .object(
                  environment.firebasePaths.space +
                    '/roadmaps/' +
                    item.parameters.roadmapId
                )
                .subscribe(
                  (roadmap: Roadmap) => {
                    item.roadmap = roadmap
                    return resolve(item)
                  },
                  (error: any) => {
                    return reject(error)
                  }
                )
            }
            break

          case 'course':
            if (!item.parameters.courseId) {
              resolve(item)
            } else {
              this.fbS
                .db(databaseUrl)
                .object(
                  environment.firebasePaths.space +
                    '/courses/' +
                    item.parameters.courseId
                )
                .subscribe(
                  (course: Course) => {
                    item.course = course
                    return resolve(item)
                  },
                  (error: any) => {
                    return reject(error)
                  }
                )
            }

            break
          case 'fluency':
            if (!item.parameters.fluencyToolId) {
              resolve(item)
            } else {
              this.fbS
                .db(databaseUrl)
                .object(
                  environment.firebasePaths.space +
                    '/fluencyTool/' +
                    item.parameters.fluencyToolId
                )

                .subscribe(
                  (fluency) => {
                    item.fluency = fluency
                    return resolve(item)
                  },
                  (error: any) => {
                    return reject(error)
                  }
                )
            }

            break
          case 'resource':
            if (!item.parameters.resourceId) {
              resolve(item)
            } else {
              this.fbS
                .db(databaseUrl)
                .object(
                  environment.firebasePaths.space +
                    '/resources/' +
                    item.parameters.resourceId
                )
                .subscribe(
                  (resource: Resource) => {
                    item.resource = resource
                    if (!item.resource) {
                      return resolve(item)
                    }
                    if (componentName && componentName != 'RoadmapComponent') {
                      this.checkExclusiveReaders(item)
                      item.description = resource.description
                      item.createdAt = resource.createdAt
                      if (item.resource.upload?.size)
                        item.fileSize = item.resource.upload.size
                      if (item.resource.type === 'package') {
                        return resolve(item)
                      }
                      this.delphireAPI
                        .postRequest('resources/getDownloadUrl', {
                          id: resource.id,
                          sourceKey: 'thumbnail_200x200'
                        })
                        .then((response) => {
                          response.subscribe((data) => {
                            console.log("DATA FROM TASK.SERVICE: ", data)
                            item.previewUrl = data.body.result.url
                            return resolve(item)
                          })
                        })
                    } else {
                      resolve(item)
                    }
                  },
                  (error: any) => {
                    return reject(error)
                  }
                )
            }

            break
          case 'library':
            if (!item.parameters.libraryId) {
              resolve(item)
            } else {
              this.fbS
                .db(databaseUrl)
                .object(
                  environment.firebasePaths.space +
                    '/libraries/' +
                    item.parameters.libraryId
                )
                .subscribe(
                  (library: Library) => {
                    item.library = library
                    return resolve(item)
                  },
                  (error: any) => {
                    return reject(error)
                  }
                )
            }

            break
          case 'quiz':
            if (!item.parameters.quizId) {
              resolve(item)
            } else {
              this.fbS
                .db(databaseUrl)
                .object(
                  environment.firebasePaths.space +
                    '/quizzes/' +
                    item.parameters.quizId
                )
                .subscribe(
                  (quiz: Quiz) => {
                    item.quiz = quiz
                    return resolve(item)
                  },
                  (error: any) => {
                    return reject(error)
                  }
                )
            }

            break
          case 'verbalizer':
            if (!item.parameters.verbalizerId) {
              resolve(item)
            } else {
              this.fbS
                .db(databaseUrl)
                .object(
                  environment.firebasePaths.space +
                    '/quizzes/' +
                    item.parameters.verbalizerId
                )
                .subscribe(
                  (verbalizer: Verbalizer) => {
                    item.verbalizer = verbalizer
                    return resolve(item)
                  },
                  (error: any) => {
                    return reject(error)
                  }
                )
            }

            break

          default:
            console.log('Type not found', item.type.toLowerCase())
            return reject()
        }
    })
  }
  constructor(
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,

    private delphireAPI: DelphireApiService
  ) {}
}
