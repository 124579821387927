import { Injectable } from '@angular/core'
import { getAnalytics, logEvent } from '@angular/fire/analytics'
import { getApp } from '@angular/fire/app'
import { ActivatedRoute } from '@angular/router'
import fastq from 'fastq'
import moment from 'moment'
import { first } from 'rxjs/operators'
import { v4 as uuidv4 } from 'uuid'

import { environment } from '../../../environments/environment'
import { AnalyticsData } from '../../interfaces/analytics-data'
import { DelphireApiService } from '../delphire-api.service'
import { LocalStorageService } from '../local-storage-service.service'
import { TrackingService } from './tracking.service'

import type { queue } from 'fastq'
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements TrackingService {
  constructor(
    private delphireAPI: DelphireApiService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute
  ) {}
  public static namespace: string = 'delphire-analytics.'
  userId!: string
  namespace!: string
  key!: string

  keysInQueue!: String[]

  queue!: queue<AnalyticsData>

  online: boolean = window.navigator.onLine

  addEvent(data: any, collection?: string): void {
    const { user, groupId, organizationId } =
      this.localStorage.get('delphireUser')

    const params = {
      namespace: 'Analytics',
      type: collection,
      id: environment.analyticsKey,
      userId: user.id,
      groupId: groupId,
      organizationId: organizationId
    }
    this.online = window.navigator.onLine
    this.userId = user.id
    this.key = environment.analyticsKey
    const combinedData: AnalyticsData = { ...data, ...params }
    if (this.online) {
      this.initQueue()
      this.addEventOnline(combinedData)
    } else {
      this.addEventOffline(combinedData)
    }
  }

  async addEventOnline({
    type,
    target,
    action,
    service,
    createdAt = moment().toString(),
    userId,
    namespace,
    id,
    originalKey
  }: AnalyticsData) {
    if (!originalKey) {
      originalKey = uuidv4()
    }
    const eventObject = {
      [originalKey]: { createdAt, type, target, action, service }
    }
    const params = {
      userId: userId,
      namespace: namespace,
      key: id,
      value: eventObject
    }

    const firebaseParams = {
      content_type: target.type,
      description: target.description?.toLowerCase(),
      page_location: this.route.url
    }

    logEvent(getAnalytics(getApp()), 'select_content', firebaseParams)

    this.delphireAPI
      .postRequest('userData/updateItem', params)
      .then((result) => {
        result.pipe(first()).subscribe(() => {})
      })
  }

  addEventOffline(data: AnalyticsData): void {
    this.localStorage.set(AnalyticsService.namespace + uuidv4(), data)
  }

  addToQueue(key: string): void {
    if (this.keysInQueue.indexOf(key) === -1) {
      this.keysInQueue.push(key)
      const props: AnalyticsData = this.localStorage.get(key)
      this.queue.push(props)
      this.removeFromQueue(key)
    }
  }

  removeFromQueue(key: string): void {
    this.localStorage.remove(key)
    if (this.keysInQueue.indexOf(key) > -1) {
      this.keysInQueue.splice(this.keysInQueue.indexOf(key), 1)
    }
  }
  initQueue(): void {
    if (!this.keysInQueue) this.keysInQueue = []
    if (!this.queue) this.queue = fastq(() => this.addEventOnline, 1)
    this.localStorage.keys(AnalyticsService.namespace).forEach((key) => {
      if (this.delphireAPI) this.addToQueue(key)
    })
  }
}
