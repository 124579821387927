import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { environment } from 'src/environments/environment'
import { Task } from '../interfaces/task'
import { DelphireLinkService } from '../services/delphire-link.service'
import { ModalGptService } from '../services/modal-gpt.service'

interface gptChatSession {
  message: {
    id: number
    text: string
  }
  response: [
    {
      text: string
      answerId: number
      trainingId: number
      trainingLink: string
      feedback?: string | null
      task?: Task
      sessionId: string
    }
  ]
}

@Component({
  selector: 'app-chat-gpt-sidebar',
  templateUrl: './chat-gpt-sidebar.component.html',
  styleUrls: ['./chat-gpt-sidebar.component.sass']
})

export class ChatGptSidebarComponent implements OnInit {
  verbose: boolean = environment.production ? false : true

  gptDocumentTitle: string | undefined
  gptQuestionAsked: string | undefined
  gptQuestionCount: number = 0
  gptChatSession: gptChatSession[] = []
  gptOpen: boolean = false
  gptSelectedTasks: Task[] = []

  selectedDocument: Task | string = 'all'
  allDocumentIds: string = ''
  trainingInfo: any = []
  agentId: string = ''
  @Input() documentTitle: string | undefined
  @Input() gptTrainingId: string | null

  @Input() item: Task | undefined;
  @Input() tasks: Task[] | [];

  constructor(
    private modalGPT: ModalGptService,
    private linkService: DelphireLinkService,
    private route: ActivatedRoute,
    private loader: SpinnerVisibilityService
  ) { }

  ngOnInit(): void {
    this.modalGPT.getTrainingInfo().subscribe((gptResponse) => {
      this.trainingInfo = gptResponse.body.items
      if (this.verbose) { console.log('%c[ this.trainingInfo ]', 'color: deeppink', this.trainingInfo) }
      let allIds: string[] = []
      this.trainingInfo.forEach((item: any) => {
        this.tasks.forEach((task) => {
          if (
            task.parameters &&
            task.parameters.resourceId === item.reference_id
          ) {
            allIds.push(String(item.training_id))
          }
        })
      })
      allIds.sort()
      this.allDocumentIds = allIds.join('-')
      // if (this.verbose) { console.log('%c[ this.allDocumentIds ]', 'color: orangered', this.allDocumentIds) }
    })

    this.modalGPT.getAgentInfo().subscribe((gptResponse) => {
      // TODO: right now there is only 1 agent ID. will need to make this dynamic once we have multiple agents
      this.agentId = String(gptResponse.body.items[0].agent_id)
    })

  }

  public open(item?: Task) {
    this.gptOpen = true
    // if (item) {
    //   if (item.gptInfo && item.parameters) {
    //     this.gptDocumentTitle = item.name
    //     this.gptTrainingId = String(item.gptInfo.training_id)
    //     this.gptSelectedTask = item
    //     if (this.verbose) {
    //       console.log(
    //         '%c[ resource ID ]',
    //         'color: yellow',
    //         item.parameters.resourceId
    //       )
    //       console.log('%c[ resource name ]', 'color: yellow', item.name)
    //       console.log(
    //         '%c[ gtp training id ]',
    //         'color: yellow',
    //         this.gptTrainingId
    //       )
    //     }
    //   }
    // }
  }

  close() {
    this.gptOpen = false
    this.gptDocumentTitle = ''
    this.gptQuestionAsked = undefined
    this.gptQuestionCount = 0
    this.gptTrainingId = null
    this.gptChatSession = []
    this.allDocumentIds = ''
    this.gptSelectedTasks = []
    this.selectedDocument = 'all'
  }

  public handleDocumentSelect(task: Task[]) {
    this.gptSelectedTasks = task
    if (this.verbose) { console.log('[ this.gptSelectedTasks ]', this.gptSelectedTasks) }
  }

  isOptionDisabled(task: Task): boolean {
    return this.gptSelectedTasks.length >= 3 && !this.gptSelectedTasks.includes(task) === true
  }

  askGPT() {

    if (this.gptQuestionAsked) {
      // console.log(this.gptSelectedTask)
      const displayQuestion = this.gptQuestionAsked
      const regex = /\?/gi
      const question = this.gptQuestionAsked.replace(regex, '')
      this.gptQuestionAsked = question

      let ask: gptChatSession = {
        message: { id: this.gptQuestionCount, text: displayQuestion },
        response: [
          {
            text: '',
            answerId: 0,
            trainingId: 0,
            trainingLink: '',
            task: this.gptSelectedTasks[0],
            sessionId: ''
          }
        ]
      }

      // let trainingId = this.gptSelectedTasks?.gptInfo?.training_id || '0'

      // if (this.selectedDocument === 'all') {
      //   trainingId = this.allDocumentIds
      // }
      let agentId = this.agentId

      let trainingIds: string[] = []
      this.gptSelectedTasks.forEach((task) => {
        if (task.gptInfo) {
          trainingIds.push(String(task.gptInfo.training_id))
        }
      })
      let selectedTrainginIds = trainingIds.join(':')

      this.gptChatSession.unshift(ask)

      this.modalGPT.getResponse(selectedTrainginIds, this.gptQuestionAsked, agentId).subscribe((gptRes) => {

        console.log('%c[ ********* getResponse() ********* ]', 'color: cyan', gptRes)

        for (let i = 0; i < gptRes.body.length; i++) {
          let responseText = gptRes.body[i].details[0].response
          let answerId = gptRes.body[i].details[0]["answer id"]
          let trainingLink = gptRes.body[i].details[0]["training link"]
          let sessionId = gptRes.body[i].details[0]["session id"]
          let trainingId = gptRes.body[i].details[0]["training id"]

          let task: Task = this.gptSelectedTasks[i]

          ask.response[i] =
          {
            text: responseText,
            answerId: answerId,
            trainingId: trainingId,
            trainingLink: trainingLink,
            task: task,
            sessionId: sessionId
          }

          if (this.selectedDocument === 'all') {
            this.tasks.forEach((item: any) => {
              if (item.gptInfo?.training_id === trainingId) {
                ask.response[i].task = item
              }
            })

          }

        }

      },
        (err) => {
          if (this.verbose) { console.log('%c[ err ]', 'color: red', err) }
          ask.response = [
            {
              text: "Sorry, I am unable to answer your question at this time. Please try again later.",
              answerId: -1,
              trainingId: 0,
              trainingLink: "",
              sessionId: ''
            }
          ]
        }
      )

      this.gptQuestionAsked = undefined
      this.gptQuestionCount++

      if (this.verbose) {
        console.log(
          '%c[ this.gptChatSession ]',
          'color: orangered',
          this.gptChatSession
        )
      }
    }
  }

  onAskKeyUp(event: any): void {
    if (event.key === 'Enter') {
      this.askGPT()
    }
  }


  giveFeedback(qaIdx: number, feedback: string): void {
    this.gptChatSession[qaIdx].response[qaIdx].feedback = feedback
    let answerId = this.gptChatSession[qaIdx].response[qaIdx].answerId
    this.modalGPT.shareFeedback(answerId, feedback).subscribe((feedbackRes) => {
      console.log('%c[ info ]', 'color: cyan', feedbackRes)
    })

    if (this.verbose) { console.log('%c[ this.gptChatSession ]', 'color: yellow', this.gptChatSession) }


  }

  async handleLink(task: Task): Promise<void> {
    if (
      task.resource?.type === 'audio' ||
      task.resource?.type === 'video'
    ) {
      const {
        completed,
        started,
        progress,
        mediaCompleted,
        mediaLastTime,
        mediaPercentage
      } = task
      task.resource = {
        ...task.resource,
        completed,
        started,
        progress,
        mediaCompleted,
        mediaLastTime,
        mediaPercentage
      }
    }
    if (task.resource?.type === 'document') {
      const {
        completed,
        started,
        progress,
        documentCompleted,
        documentPercentage,
        documentLastTime
      } = task
      task.resource = {
        ...task.resource,
        completed,
        started,
        progress,
        payload: {
          ...task.resource.payload,
          documentCompleted,
          documentPercentage,
          documentLastTime,
          currentPage: documentLastTime
        },
        documentCompleted,
        documentPercentage,
        documentLastTime
      }
    }
    this.linkService.handleLink({
      type: task.resource?.type,
      resource: task.resource,
      route: this.route
    })
  }


}
