<div class="roadmap-weekly">
  <h3>Select the drop-down arrow to navigate sections of the roadmap</h3>

  <!-- ------------------------------ NAV LEVEL 1: DAILY (CHOOSE WEEK) ------------------------------ -->

  <ng-container class="roadmap-nav roadmap-nav-level-1">
    <mat-select [(value)]="currentWeekNum" id="roadmap-select-weekly">
      <mat-option class="roadmap-select-option" *ngFor="let week of roadmap.weeks" [value]="week.number">Week {{ week.number + 1 }}</mat-option>
    </mat-select>
  </ng-container>

</div>

<!-- ===================================================================================================================== -->
<!-- ============================== CONTENT LOOP ==================================================================== -->
<!-- ===================================================================================================================== -->

<div class="roadmap-content-box" *ngIf="filteredTasks && filteredTasks.length > 0">

  <!-- ------------------------------ NAV LEVEL 2: DAILY (CHOOSE DAY) ------------------------------ -->

  <ng-container class="roadmap-nav roadmap-nav-level-2 days-nav">
    <mat-select [(value)]="currentDayNum" id="roadmap-select-custom-level-2">
      <mat-option class="roadmap-select-option" *ngFor="let day of weekDays; let i = index" [value]="i">Day {{ i + 1 }}</mat-option>
    </mat-select>
  </ng-container>

  <!-- ------------------------------ OBJECTIVES LEVEL 2: DAILY ------------------------------ -->

  <div class="roadmap-objectives roadmap-objectives-level-2 roadmap-border-bottom" *ngIf="objectives.length">
    <div class="roadmap-weekly-header">
      <h3 class="roadmap-weekly-title">{{ currentWeek.title }}</h3>
    </div>
    <div class="roadmap-weekly-subheader" *ngIf="currentWeek.subtitle">
      <h4 class="roadmap-weekly-subtitle">{{ currentWeek.subtitle }}</h4>
    </div>
    <div class="roadmap-weekly-content-hold">
      <div class="roadmap-weekly-content" *ngFor="let s of currentWeek.sections" [ngSwitch]="s.type">
        <div class="roadmap-weekly-content-list" *ngSwitchCase="'list'">
          <ul>
            <li *ngFor="let li of s.listItems">{{ li }}</li>
          </ul>
        </div>
        <div class="roadmap-weekly-content-text" *ngSwitchCase="'text'">{{ s.value }}</div>
      </div>
    </div>
  </div>


  <div class="roadmap-tasks padded">
    <app-task *ngFor="let t of filteredTasks" [class]="'roadmap-item'" [task]="t"></app-task>
  </div>
</div>

<div class="roadmap-tasks roadmap-tasks-empty" *ngIf="!filteredTasks.length" [innerHtml]="'language.noTasksThisDay'"></div> <!-- to ask -->