import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { DelphireApiService } from './delphire-api.service'
import { LocalStorageService } from './local-storage-service.service'

@Injectable({
  providedIn: 'root'
})

export class ModalGptService {

  constructor(
    private http: HttpClient,
    private delphireAPI: DelphireApiService,
    private localStorage: LocalStorageService,
  ) 
  
  { 
    if(environment.modalGPTConfig.enabled){
      this.setHeader() 
    }
  
  }


  headers: {} = {}
  baseUrl = "https://api.modaldigital.io/chatgpt/"


  setHeader() {
    this.delphireAPI.getApiToken().then((res) => {
      this.headers = {
        'Authorization': 'Bearer ' + this.delphireAPI.authToken,
        // 'Content-Type': 'application/json',
        'Content-Type': 'text/plain',
        "hide-ng-http-loader": 'true'
      }
      console.log(this.headers)
    })
  }


  getTrainingInfo(): Observable<HttpResponse<any>> {
    console.log('%c[ this.headers ]', 'color: orange', this.headers)
    const requestUrl = this.baseUrl + 'getTrainingInfo/'
    return this.http.get<any>(requestUrl, {
      params: {},
      headers: this.headers,
      observe: 'response',
      responseType: 'json'
    })
  }


  getAgentInfo(): Observable<HttpResponse<any>> {
    console.log('%c[ this.headers ]', 'color: orange', this.headers)
    const requestUrl = this.baseUrl + 'getAgentInfo/'
    return this.http.get<any>(requestUrl, {
      params: {},
      headers: this.headers,
      observe: 'response',
      responseType: 'json'
    })
  }


  //* getResponse/{{training_id}}/{{prompt}}/{{agent_id}}
  getResponse(training_id: string, prompt: string, agent_id: string): Observable<HttpResponse<any>> {
    // console.log('%c[  getResponse headers ]', 'color: yellow', this.headers)
    const requestUrl = `${this.baseUrl}getResponse/${training_id}/${prompt}/${agent_id}/`
    console.log('%c[ requestUrl ]', 'color: deeppink', requestUrl)
    return this.http.post<any>(requestUrl, null, {
      params: {},
      headers: this.headers,
      observe: 'response',
      responseType: 'json'
    })
  }


  //* getResponseSession/{{training_id}}/{{prompt}}/{{agent_id}}/{{session_id}}
  getResponseSession(training_id: string, prompt: string, agent_id: string, session_id: string): Observable<HttpResponse<any>> {
    console.log('%c[ getResponseSession headers ]', 'color: yellow', this.headers)
    const requestUrl = `${this.baseUrl}getResponse/${training_id}/${prompt}/${agent_id}/${session_id}/`
    return this.http.post<any>(requestUrl, null, {
      params: {},
      headers: this.headers,
      observe: 'response',
      responseType: 'json'
    })
  }


  //* shareFeedback/{{user_name}}/{{answer_id}}/{{feedback}}
  shareFeedback(answerId: number, feedback: string): Observable<HttpResponse<any>> {
    let delphireUser = this.localStorage.get('delphireUser')
    let userName = delphireUser.user.email.replace('@', '%40')
    const requestUrl = `${this.baseUrl}shareFeedback/${userName}/${answerId}/${feedback}/`
    return this.http.get<any>(requestUrl, {
      params: {},
      headers: this.headers,
      observe: 'response'
    })
  }

}
