import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { getApp } from '@angular/fire/app'
import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where
} from '@angular/fire/firestore'
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage'
import { MatPaginator } from '@angular/material/paginator'
import { MatSelect } from '@angular/material/select'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute } from '@angular/router'
import _ from 'lodash'
import moment from 'moment'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { DocumentData } from 'rxfire/firestore/interfaces'
import { from, Subscription } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { environment } from '../../environments/environment'
import { DelphireUtilities } from '../../utilities/delphire-utilities'
import { ExternalLink } from '../interfaces/external-link'
import { ProgressData } from '../interfaces/progress-data'
import { Resource } from '../interfaces/resource'
import { Space } from '../interfaces/space'
import { Task } from '../interfaces/task'
import { ConfirmationDialogService } from '../services/confirmation-dialog.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { ProgressService } from '../services/progress.service'

export interface ProgressId extends ProgressData {
  docId: string
}
@Component({
  selector: 'app-my-learnings',
  templateUrl: './my-learnings.component.html',
  styleUrls: ['./my-learnings.component.sass']
})
export class MyLearningsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inProgressPaginator')
  inProgressPaginator!: MatPaginator

  @ViewChild('completePaginator')
  completePaginator!: MatPaginator

  @ViewChild('selectInProgress')
  selectInProgress!: MatSelect

  @ViewChild('selectCompleted')
  selectCompleted!: MatSelect
  imageUrl: string | undefined

  myDescendantsArray: String[] = []

  myLearningsCompleted: boolean = true

  myLearningsComplete: Task[] = []

  externalLinks: Task[] = []

  myLearningsInProgress: Task[] = []

  queryInProgress: any = {
    limit: 30
  }

  rawResources: Task[] = []

  progressData: Observable<ProgressId[]> | undefined

  progressDataCleaned: any[] = []

  progress: ProgressId[] = []

  subscribers: Subscription[] = []

  dataSource = new MatTableDataSource<Task>(this.myLearningsInProgress)

  dataSourceComplete = new MatTableDataSource<Task>(this.myLearningsComplete)
  sortersIncomplete: String[] = ['name']

  sortOptionsIncomplete: String[] = ['name', 'date', 'percentage']
  selectedSorterIncomplete: string = 'name'
  sortOptionsComplete: String[] = ['name', 'date']
  sortersComplete: String[] = ['name']

  selectedSorterComplete: string = 'date'

  myLearningActiveSection: string = 'progress-mine'

  brandName: string = ''

  openItem(task: Task): void {
    let space: Space = this.localStorage.get('currentSpace')
    if (task.tenantId !== space.id) {
      const { tenants } = this.localStorage.get('delphireUser')
      const newSpace: Space = _.find(tenants, { id: task.tenantId })
      if (newSpace) {
        this.localStorage.set('currentSpace', newSpace)
      } else {
        const dialogOptions = {
          title: 'Archived or Inaccessible Space',
          message:
            'The item you are trying to access is either in an archived space or you no longer have access to the space. If you believe this is incorrect please contact an administrator.',
          cancelText: 'CANCEL',
          confirmText: 'OK'
        }
        task.type = undefined
        this.dialogService.open(dialogOptions)

        this.dialogService.confirmed().subscribe((confirmed) => {})
      }
    }
    if (task.type) {
      switch (task.type) {
        case 'audio':
        case 'video':
          if (
            task.resource?.type === 'audio' ||
            task.resource?.type === 'video'
          ) {
            const {
              completed,
              started,
              progress,
              mediaCompleted,
              mediaLastTime,
              mediaPercentage
            } = task
            task.resource = {
              ...task.resource,
              completed,
              started,
              progress,
              mediaCompleted,
              mediaLastTime,
              mediaPercentage
            }
          }
          this.linkService.handleLink({
            type: task.resource?.type,
            resource: task.resource,
            route: this.route
          })
          break
        case 'document':
        case 'package':
          this.linkService.handleLink({
            type: task.resource?.type,
            resource: task.resource,
            route: this.route
          })
          break
        case 'externalLink':
          const { databaseUrl } = this.localStorage.get('currentSpace')
          this.fbs
            .db(databaseUrl)
            .object(
              environment.firebasePaths.space + 'externalLinks/' + task.id
            )
            .subscribe((extLink) => {
              if (extLink) {
                const { url } = extLink as ExternalLink
                window.open(url, '_blank')
              } else {
                window.open(task.name, '_blank')
              }
            })
          break

        default:
          break
      }
    }
  }
  isFavorite(item: Task): boolean {
    return false
  }
  toggleFavorite(item: Task): boolean {
    const excluded = ['External Link', 'Quiz', 'Fluency', 'Course', 'Library']
    if (item.type && excluded.indexOf(item.type) > -1) return false
    else return true
  }
  favorite(resourceId: string | undefined): void {}
  getSpaceImage(): Observable<string> {
    const space: Space = this.localStorage.get('currentSpace')
    const refUrl: string = 'Logos/' + space.name + '.png'
    const app = getApp()
    const storage = getStorage(app)
    const imgRef = ref(storage, refUrl)
    return from(getDownloadURL(imgRef))
  }

  mobileBrowser(): boolean {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      return true
    } else {
      // false for not mobile device
      return false
    }
  }

  setDataSources(): void {
    this.myLearningsInProgress = []
    this.myLearningsComplete = []
    //Clean out bad data from resources removed manually by Brendan :)
    this.progressDataCleaned = _.filter(
      this.progressDataCleaned,
      (p: { name: any }) => {
        return p.name
      }
    )
    this.progressDataCleaned = _.concat(
      this.progressDataCleaned,
      this.externalLinks
    )

    // filter out quizzes because we dont track quiz progress
    const filteredByInProgress = _.filter(
      this.progressDataCleaned,
      (r: { completed: any, type: string }) => {
        return !r.completed && r.type != 'Quiz'
      }
    )

    // filter to allow quizzes that have a score in their payload
    const filteredByCompletion = _.filter(
      this.progressDataCleaned,
      (r: { completed: any, type: string, payload: any }) => {
        if(r.type == 'Quiz') {
          return r.payload.score !== undefined
        } 
        return r.completed
      }
    )
    this.myLearningsInProgress = _.sortBy(filteredByInProgress, 'name', 'desc')
    //console.log(_.sortBy(this.myLearningsInProgress, 'type', 'desc'))
    this.dataSource.data = this.myLearningsInProgress
    this.dataSource.paginator = this.inProgressPaginator

    this.myLearningsComplete = _.orderBy(
      filteredByCompletion,
      'completionDate',
      ['desc']
    ) as Task[]

    this.dataSourceComplete.data = this.myLearningsComplete
    this.dataSourceComplete.paginator = this.completePaginator

    this.spinner.hide()
  }
  getSpaceName(id: string): string {
    const { tenants } = this.localStorage.get('delphireUser')
    const space = _.find(tenants, { id: id })
    if (space) {
      return space.name
    } else {
      return 'archived space'
    }
  }
  getResources(): void {
    if (this.progressDataCleaned)
      this.progressDataCleaned.forEach((prog, index) => {
        const progressData: ProgressId[] = _.mapValues(
          _.groupBy(this.progress, 'resourceId'),
          (plist) =>
            plist.map((p) => {
              if (p.resourceId === prog.resourceId) {
                return p
              } else {
                return p
              }
            })
        )[prog.resourceId]

        const { userDataType } = prog
        let node: string = 'resources'
        if (userDataType === 'quizprogress') {
          node = 'quizzes'
        }
        const app = getApp()
        const firestore = getFirestore(app)
        const docRef = doc(firestore, node + '/' + prog.resourceId)
        getDoc(docRef).then((doc) => {
          if (doc.exists()) {
            const resource: Resource = doc.data() as Resource
            const { id } = resource as Resource
            resource.payload = prog.payload
            let item: Task = {
              id: id ? id : '',
              resource: resource as Resource,
              name: resource.name,
              type: resource.type,
              completionDate: moment(prog.createdAt).toISOString()
            }

            if (resource.type) {
              item = this.progressService.updateProgress(
                item,
                progressData,
                resource.type
              )
            }
            prog = { ...prog, ...item }

            this.progressDataCleaned.splice(index, 1, prog)
          }
          if (this.progressDataCleaned.length === index + 1)
            this.setDataSources()
        })
      })
  }
  async getMyLearnings(): Promise<void> {
    const { user } = this.localStorage.get('delphireUser')
    this.spinner.show()
    const app = getApp()
    const firestore = getFirestore(app)
    const progressCol = collectionGroup(firestore, 'progress')
    const q = query(
      progressCol,
      where('userId', '==', user.id),
      where('userDataType', '!=', 'userinfo')
    )
    const docs = await getDocs(q)
    docs.forEach((doc) => {
      const data = doc.data() as ProgressData
      const docId = doc.id
      this.progress.push({ ...data, docId } as ProgressId)

      if (this.progress.length === docs.size) {
        const cleanData: ProgressData[] = this.progress.filter((d) => {
          return d.resourceId && DelphireUtilities.isGuid(d.resourceId)
        })

        this.progressDataCleaned = _.uniqBy(cleanData, 'resourceId')
        this.getResources()
      }
    })

    const analyticsCol = collectionGroup(firestore, 'analytics')
    const aQ = query(
      analyticsCol,
      where('userId', '==', user.id),
      where('service', '==', 'LinkService'),
      where('target.type', '==', 'External Link')
    )

    const linkDocs = await getDocs(aQ)
    const allLinks: DocumentData[] = []
    linkDocs.forEach((link) => {
      allLinks.push(link.data())
      if (allLinks.length === linkDocs.size) {
        const links = _.uniqBy(allLinks, 'target.id')
        this.externalLinks = []
        links.forEach((extLink: any) => {
          if (extLink.target) {
            const task: Task = {
              id: extLink.target.id || '',
              type: 'externalLink',
              name: extLink.target.description,
              completionDate: moment(extLink.createdAt).toISOString(),
              completed: true
            }
            this.externalLinks.push(task)
          }
        })
      }
    })
  }

  delphireSearchActive(): boolean {
    return false
  }
  constructor(
    private localStorage: LocalStorageService,
    // private afs: FireStoreServiceService,

    private fbs: FirebaseService,
    private progressService: ProgressService,
    private spinner: SpinnerVisibilityService,
    private linkService: DelphireLinkService,
    private route: ActivatedRoute,
    private dialogService: ConfirmationDialogService
  ) {}

  ngOnInit(): void {
    this.brandName = environment.name
    this.imageUrl = 'brand/' + this.brandName + '/assets/images/brand-logo.png'
    this.getMyLearnings()
    this.dataSource = new MatTableDataSource<Task>(this.myLearningsInProgress)

    this.dataSourceComplete = new MatTableDataSource<Task>(
      this.myLearningsComplete
    )
    this.dataSourceComplete.paginator = this.completePaginator

    if (this.dataSourceComplete.paginator) {
      this.dataSourceComplete.paginator.firstPage()
    }
  }
  watchCompletedSelection(): void {
    this.selectCompleted.selectionChange.subscribe((selection) => {
      switch (selection.value) {
        case 'name':
          this.myLearningsComplete = _.sortBy(this.myLearningsComplete, 'name')
          break
        case 'date':
          this.myLearningsComplete = _.orderBy(
            this.myLearningsComplete,
            'completionDate',
            ['desc']
          )
          break

        default:
          break
      }
      this.dataSourceComplete = new MatTableDataSource<Task>(
        this.myLearningsComplete
      )
      this.dataSourceComplete.paginator = this.completePaginator
      this.dataSourceComplete.paginator?.firstPage()
    })
  }
  watchProgressSelection(): void {
    this.selectInProgress.selectionChange.subscribe((selection) => {
      switch (selection.value) {
        case 'name':
          this.myLearningsInProgress = _.sortBy(
            this.myLearningsInProgress,
            'name'
          )
          break
        case 'date':
          this.myLearningsInProgress = _.orderBy(
            this.myLearningsInProgress,
            'completionDate'
          )
          break
        case 'percentage':
          const nestedValueComparator = (item: any) => {
            console.log('item on sort', item)
            if (item?.payload?.precentage) {
              return _.get(item, 'payload.precentage') as Task
            } else {
              return _.get(item, 'payload.percentage') as Task
            }
          }
          this.myLearningsInProgress = _.orderBy(
            this.myLearningsInProgress,
            nestedValueComparator,
            ['desc']
          )
          break
        default:
          break
      }
      this.dataSource = new MatTableDataSource<Task>(this.myLearningsInProgress)
      this.dataSource.paginator = this.inProgressPaginator
      this.dataSource.paginator?.firstPage()
    })
  }
  ngAfterViewInit(): void {
    this.watchProgressSelection()
    this.watchCompletedSelection()
  }

  ngOnDestroy(): void {
    console.log('ngDestroy')
    this.subscribers.forEach((subscriber) => {
      subscriber.unsubscribe()
    })
  }
}
