<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content class="mat-typography">
  <div class="dialog-message" >{{data.instruction}}</div>
  <textarea class="self-complete-reason" [(ngModel)]="data.reason" spellcheck="false"></textarea>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button *ngIf="data.task.selfCompleted" [mat-dialog-close]="'self-complete-reset-progress'" class="mat-raised-button btn-reset">Reset Completion Progress</button>
  <span class="spacer"></span>
  <button mat-button [mat-dialog-close]="'self-complete-cancel-dialog'" class="mat-raised-button btn-cancel">Cancel</button>
  <button mat-button [mat-dialog-close]="data.reason" [disabled]="!data.reason" cdkFocusInitial class="mat-raised-button btn-confirm" >{{data.task.selfCompleted ? 'Resubmit Reason' : data.submitText}}</button>
</mat-dialog-actions>
