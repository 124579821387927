import { Injectable } from '@angular/core'
import { getAnalytics, logEvent } from '@angular/fire/analytics'
import { getApp, initializeApp } from '@angular/fire/app'
import { ActivatedRoute } from '@angular/router'
import type { queue } from 'fastq'
import fastq from 'fastq'
import { get, getDatabase, ref } from 'firebase/database'
import moment from 'moment'
import { first } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { v4 as uuidv4 } from 'uuid'
import { ProgressAnalyticsData } from '../../interfaces/progress-analytics-data'
import { DelphireApiService } from '../delphire-api.service'
import { LocalStorageService } from '../local-storage-service.service'
import { TrackService } from './track.service'
import { TrackingService } from './tracking.service'


@Injectable({
  providedIn: 'root'
})
export class ProgressService implements TrackingService {
  public static namespace: string = 'delphire-analytics.'

  userId!: string
  namespace!: string
  key!: string

  keysInQueue!: String[]
  shardSnapshot!: any

  queue!: queue<ProgressAnalyticsData>

  online: boolean = window.navigator.onLine
  addEvent(data: any, collection?: string): void {
    const { user, groupId, organizationId } =
      this.localStorage.get('delphireUser')

    const params = {
      namespace: data.namespace,
      type: data.type,
      id: data.id,
      userId: user.id,
      groupId: groupId,
      organizationId: organizationId
    }
    this.online = window.navigator.onLine
    this.userId = user.id
    this.key = data.id

    console.log('%c Adding Event: data and params', 'color: yellow');
    console.log(data);
    console.log(params);

    const combinedData: ProgressAnalyticsData = { ...data, ...params }
    if (this.online) {
      this.initQueue()
      this.addEventOnline(combinedData)
    } else {
      this.addEventOffline(combinedData)
    }
  }

  sendToFBAnalytics(params: any): void {
    const resourceType: string = this.track.getResourceType()
    if (resourceType && resourceType === 'module') {
      return
    }

    console.log('%c FB ANALYTICS, w/ params:', 'color: yellow');
    console.log(params);

    if (resourceType && resourceType !== 'quiz') {
      let fbEvent = `${resourceType}_playback`
      let position = {
        [`${resourceType}_position`]:
          params.value[params.key].payload.currentTime
      }
      let duration = {
        [`${resourceType}_duration`]: params.value[params.key].payload.duration
      }
      let event = {
        [`${resourceType}_event`]:
          params.value[params.key].payload.percentage === 0 ? 'start' : 'ended'
      }
      if (resourceType === 'document') {
        fbEvent = `${resourceType}_progress`
        position = {
          [`${resourceType}_page`]: params.value[params.key].payload.currentPage
        }
        duration = {
          [`${resourceType}_length`]: params.value[params.key].payload.pageCount
        }
        event = { [`${resourceType}_event`]: 'progress' }
      }
      if (resourceType === 'module') {
        fbEvent = `${resourceType}_progress`
        position = {
          [`${resourceType}_page`]: params.value[params.key].payload.currentPage
        }
        duration = {
          [`${resourceType}_length`]: params.value[params.key].payload.pageCount
        }
        event = { [`${resourceType}_event`]: 'progress' }
      }
      const firebaseParams = {
        ...event,
        [`${resourceType}_id`]: params.key,
        [`${resourceType}_title`]: params.value[params.key].name,
        ...position,
        ...duration,
        [`${resourceType}_progress`]:
          params.value[params.key].payload.percentage
      }
      logEvent(getAnalytics(getApp()), fbEvent, firebaseParams)
    }
    if (resourceType && resourceType === 'quiz') {
      let quizPayload = params.value[params.key].payload
      let fbEvent = 'quiz_start_event'
      let firebaseQuizParamsDefault = {
        item_id: params.key,
        attemptNum: quizPayload.attemptNum
      }
      let firebaseQuizParams, currentAttempt, currentAnswer
      switch(true) {
        case (params.value[params.key].type === 'QUIZ_ANSWER'):
                                                                                                  // console.log('%c[ QUIZ TRACKING QUIZ_ANSWER ]', 'color: red')
          currentAttempt = quizPayload.attempts[quizPayload.attempts.length - 1]
                                                                                                  // console.log('%c[ QUIZ TRACKING currentAttempt ]', 'color: red', currentAttempt)
          currentAnswer = currentAttempt.answers[currentAttempt.answers.length - 1]
                                                                                                  // console.log('%c[ QUIZ TRACKING currentAnswer ]', 'color: red', currentAnswer)
          fbEvent = 'quiz_answer_event'
          firebaseQuizParams = {
            //...firebaseQuizParams.item_id,
            //...firebaseQuizParams.attemptNum,
            item_id: firebaseQuizParamsDefault.item_id,
            attemptNum: firebaseQuizParamsDefault.attemptNum,
            question_Id: currentAnswer.question.id, // quizPayload.question_id,
            answered: currentAnswer.answered, // quizPayload.answered,
            correct: currentAnswer.answeredCorrect // quizPayload.correct
          }
          break;
        case (params.value[params.key].type === 'QUIZ_COMPLETE'):
        case (params.value[params.key].type === 'QUIZ_FAIL'):
                                                                                                  // console.log('%c[ QUIZ TRACKING QUIZ_COMPLETE ]', 'color: red', params.value[params.key].type)
          currentAttempt = quizPayload.attempts[quizPayload.attempts.length - 1]
                                                                                                  // console.log('%c[ QUIZ TRACKING currentAttempt ]', 'color: red', currentAttempt)
          fbEvent = 'quiz_completion_event'
          firebaseQuizParams = {
            //...firebaseQuizParams.item_id,
            //...firebaseQuizParams.attemptNum,
            item_id: params.key,
            attemptNum: quizPayload.attemptNum,
            score: currentAttempt.score, // quizPayload.score,
            status:
              params.value[params.key].type === 'QUIZ_COMPLETE'
                  ? 'passed'
                  : 'failed'
          }
        break;
        default:
          console.log('%c[ QUIZ TRACKING QUIZ_START ]', 'color: red', params.value[params.key].type)
          firebaseQuizParams = firebaseQuizParamsDefault
      }
      // console.log('%c[ QUIZ TRACKING logEvent ]', 'color: red')
      // console.log('%c[ QUIZ TRACKING fbEvent ]', 'color: red', fbEvent)
      // console.log('%c[ QUIZ TRACKING firebaseQuizParamsDefault ]', 'color: red', firebaseQuizParamsDefault)
      console.log('%c[ QUIZ TRACKING firebaseQuizParams ]', 'color: red', firebaseQuizParams)
      logEvent(getAnalytics(getApp()), fbEvent, firebaseQuizParams)
    }
  }
  addEventOnline({
    type,
    name,
    payload,
    createdAt = moment().toISOString(),
    userId,
    namespace,
    id,
    originalKey
  }: ProgressAnalyticsData): void {
    if (!originalKey) {
      originalKey = uuidv4()
    }
    const eventObject = {
      [originalKey]: { createdAt, type, name, payload }
    }

    const shardOriginalKey: string = originalKey

    const params = {
      userId: userId,
      namespace: namespace,
      key: id,
      value: eventObject
    }


    console.log('------- type', type)
    console.log('------- name', name)
    console.log('------- payload', payload)
    console.log('------- namespace', namespace)

    const space = this.localStorage.get('currentSpace')
    const dataShard = initializeApp({
      ...environment.firebaseConfig, databaseURL: space.databaseUrl
    }, 'dataShard')

    const shardDB = getDatabase(dataShard)

    const path = `environments/production/userData/${params.userId}/${namespace}/${originalKey}/${originalKey}/payload`;

    const FBRef = ref(shardDB, path);

    let shardSnapshot

    get(FBRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.shardSnapshot = snapshot.val()

        if (eventObject[shardOriginalKey].payload.completed && !this.shardSnapshot?.firstCompletedOn) {
          payload.firstCompletedOn = moment(Date())
        } else {
          if (this.shardSnapshot?.firstCompletedOn) {
            payload.firstCompletedOn = this.shardSnapshot.firstCompletedOn
          }
        }
      } else {
        console.log("No data available");
      }

      // sendToFBAnalytics was already ignoring modules
      if(namespace !== 'SelfCompletionProgress' && namespace !== 'ModuleProgress') {
        this.sendToFBAnalytics(params)
      }

      this.delphireAPI
        .postRequest('userData/updateItem', params)
        .then((result) => {
          result.pipe(first()).subscribe(() => {})
        })
    })
  }

  addEventOffline(data: ProgressAnalyticsData): void {
    this.localStorage.set(ProgressService.namespace + uuidv4(), data)
  }

  addToQueue(key: string): void {
    if (this.keysInQueue.indexOf(key) === -1) {
      this.keysInQueue.push(key)
      const props: ProgressAnalyticsData = this.localStorage.get(key)
      this.queue.push(props)
      this.removeFromQueue(key)
    }
  }

  removeFromQueue(key: string): void {
    this.localStorage.remove(key)
    if (this.keysInQueue.indexOf(key) > -1) {
      this.keysInQueue.splice(this.keysInQueue.indexOf(key), 1)
    }
  }
  initQueue(): void {
    if (!this.keysInQueue) this.keysInQueue = []
    if (!this.queue) this.queue = fastq(() => this.addEventOnline, 1)
    this.localStorage.keys(ProgressService.namespace).forEach((key) => {
      if (this.delphireAPI) this.addToQueue(key)
    })
  }
  constructor(
    private delphireAPI: DelphireApiService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private track: TrackService
  ) {}
}

